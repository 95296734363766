<template>
  <div>
    {{ $route.name }}
  </div>
</template>

<script>
export default {
  name: "RankingsComponent",
};
</script>

<style></style>
